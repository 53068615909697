.buttonsRow {
    margin-top: 2rem;
}

.columnRow {
    display: flex;
    flex-direction: column;
}

.editOrderButton {
    font-size: 1.1rem;
}

.form {
    margin-top: 1rem;
}