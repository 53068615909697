.buttonsRow {
    margin-top: 2rem;
}

.columnRow {
    display: flex;
    flex-direction: column;
}

.formTitle {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
    font-weight: bold;
}

.editOrderButton {
    font-size: 1rem;
}

.form {
    margin-top: .5rem;
}